import { Helmet } from 'react-helmet'
import "../style/features.css"

const Features = () => {
    return (
        <section>
            <Helmet>
				<meta charSet="utf-8" />
				<title>Amazing features you can find on Whokup</title>
			</Helmet>
            <div className="row">
                <div className='col-md-12'>
                    <div className='recent'>
                        <div className='row'>
                            <h4>Whokup Features</h4>
                            <div className='col-md-12'>
                                <p><span></span> Discovery of nearby locations of interest for an activity or interests. </p>
                            </div>
                            <div className='col-md-12'>
                                <p><span></span> Tag/register new locations. </p>
                            </div>
                            <div className='col-md-12'>
                                <p><span></span> Conduct your in-app and off-app financial transactions on PayUp. </p>
                            </div>
                            <div className='col-md-12'>
                                <p><span></span> Provide and share feedback on service experience at different locations of interest. </p>
                            </div>
                            <div className='col-md-12'>
                                <p><span></span> Invite friends to join and share your experiences with each other directly. </p>
                            </div>
                            <div className='col-md-12'>
                                <p><span></span> Play daily games and activities to earn extra e-Coins. </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='col-md-6'>
                    <div className='recent'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h5><span></span> A Payment System</h5>
                                <p>
                                    You get to earn points and badges for tagging locations and oher operations on 
                                    the mobile application, this can in turn be converted to actual money through our Whokpay feature.
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <h5><span></span> Vibes and Cruise</h5>
                                <p>
                                    With the Vibes n Cruise feature, you get to do more than just tagging locations on the 
                                    Whokup application, you can connect with friends, share photos and videos also.
                                </p>
                            </div>
                        </div>
                    </div>    
                </div> */}
            </div>
        </section>
    )
}

export default Features