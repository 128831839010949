import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Features from "./pages/Features";
import About from "./pages/About";
import Policy from "./pages/Policy";
import Faq from "./pages/Faq";

import "./app.css";
import Deactivate from "./pages/Deactivate";
import Campaign from "./pages/Campaign";
import Layout from "./Layout";

const App = () => {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="" element={<Layout />}>
						<Route path="/" element={<Home />} />
						<Route path="about" element={<About />} />
						<Route path="features" element={<Features />} />
						<Route path="policy" element={<Policy />} />
						<Route path="faq" element={<Faq />} />
						<Route path="deactivate" element={<Deactivate />} />
					</Route>
					<Route path="campaign" element={<Campaign />} />
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default App;
