import { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Container,
} from 'reactstrap';

import '../style/main.css';

import Home from '../assets/images/Simulator Screen Shot - iPhone 13 Pro Max - 2022-10-08 at 12.52.png'
import Profile from '../assets/images/Settings.png'
import Location from '../assets/images/add location.png'
import PayUp from '../assets/images/Pay.png'
import Activities from '../assets/images/connect.png'



const items = [
  {
    id: 1,
    src: Home,
    title: "Home:",
    decription: "Discover locations of interest around you. Click, read the description of the location, learn the feedback by other customers at the location, see its ratings etc."
  },
  {
    id: 2,
    src: Location,
    title: "Location:",
    decription: "See a map display of your current location, tag/register a new location, confirm your current location."
  },
  {
    id: 3,
    src: PayUp,
    title: "PayUp:",
    decription: "Earn e-Coins from activities and feedback. Convert e-Coins on PayUp to cash. Transfer e-Coins, pay bills, buy mobile data, credit your account etc."
  },
  {
    id: 4,
    src: Activities,
    title: "Activities:",
    decription: "View a history of locations you've visited and locations you've shared with your friends."
  },
  {
    id: 5,
    src: Profile,
    title: "Profile:",
    decription: "Manage your account, participate in games, change your settings etc."
  }
];

const Carouse = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex); 
  }


  
  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className='container '>
          <div className='row '>
            <div className='home__carousel carousel-content'>
              <div className='col-md-6'>
                <div className="">
                    <img src={item.src} alt='product_image' width="250px" height="70%"  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='home__text'>
                  <h4>{item.title}</h4>
                  <p className='home__description'>
                    {item.decription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      dark={true}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} className='carousel-control-prev-icon' />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} className='carousel-control-next-icon' />
    </Carousel>
  );
}

export default Carouse;

