import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

const Deactivate = ({ history }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [load, setLoad] = useState(false);

	const deactivateHandler = async () => {
		try {
			setLoad(true);
			const { data } = await axios.post(
				`https://api.whokup.com/api/deactivate`,
				{ email, password }
			);
			setLoad(false);
			if (data.status === "ok") {
				history.push("/");
			} else {
				alert(
					"We are unable to accept request at this time. Please try again later"
				);
			}
		} catch (err) {
			setLoad(false);
			alert("An Error has occured. Please contact Admin.");
		}
	};

	return (
		<div className="about policy">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Read through our policies to understand us better.
				</title>
			</Helmet>
			<div className="row">
				<div className="col-lg-12">
					<div className="left__content">
						<h4>Deactivate your account?</h4>
						<p>
							If you want to start the process of deactivating
							your Whokup account, please note that your
							information will no longer be used on the platform.
						</p>
						<p>
							If you just want to change some of your information
							details, you can access the "Edit Profile" screen to
							do that. For details you can't change, please send a
							mail to help@whokup.com and we will assist you from
							there.
						</p>
						<p>
							Please note that you can reactivate your account
							within 30 days after deactivation. This is
							neccessary for cases where the acccount was
							accidentally deactivated. Once again, you have to
							send us a mail to do this.
						</p>
						<p>
							You can fill the form below to deactivate your
							account:
						</p>
						<div className="row">
							<div className="col-md-6">
								<div className="form">
									<input
										type="email"
										value={email}
										onChange={(e) =>
											setEmail(e.target.value)
										}
										disabled={load}
										placeholder="Email Address"
									/>
									<input
										type="password"
										value={password}
										onChange={(e) =>
											setPassword(e.target.value)
										}
										disabled={load}
										placeholder="Your Password"
									/>
									<button
										onClick={deactivateHandler}
										disabled={load}
									>
										{load ? "Hold..." : "Deactivate"}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Deactivate;
