import { Helmet } from 'react-helmet'
import "../style/about.css"

const About = () => {
    return (
        <div className="about">
            <Helmet>
				<meta charSet="utf-8" />
				<title>About Us | Whokup is an application, developed to improve location experiences</title>
			</Helmet>
            <div className="row">
                <div className="col-lg-12">
                    <div className="left__content">
                        <h5>About Us</h5>
                        <p>
                            Whokup is a customer experience management and social accountability location-based solution that helps users access and provide real-time feedback at different locations of interest
                        </p>
                        <p>
                            Whokup is a community of users who wish to share their experiences at different locations of interest. Good experiences are praised and poor experiences are highlighted. This will help improve the quality of service 
                            delivery, better decision making, encourage positive competition between service providers, encourage regulatory compliance and generally give users value for their money and time.

                            Users earn e-Coins for participation and activities and this token can be converted to useful cash.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About