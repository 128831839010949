import React from "react";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";

const Layout = () => {
	return (
		<div className="app">
			<Sidebar />
			<div className="wrap">
				<div className="content">
					<main className="main">
						<Navbar />
						<Outlet />
					</main>
				</div>
				<Footer />
			</div>
		</div>
	);
};

export default Layout;
