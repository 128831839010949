import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
	AiFillFacebook,
	AiFillInstagram,
	AiFillTwitterSquare,
	AiOutlineContacts,
	AiOutlineMail,
	AiFillPhone,
} from "react-icons/ai";
import { DiAppstore } from "react-icons/di";
import { FaGooglePlay, FaLocationArrow } from "react-icons/fa";

import "../style/footer.css";

const Footer = () => {
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	const externalCloseBtn = (
		<button
			type="button"
			className="close"
			style={{ position: "absolute", top: "15px", right: "15px" }}
			onClick={toggle}
		>
			&times;
		</button>
	);

	return (
		<div className="footer__section">
			<div className="container-fluid">
				<div className="footer__container">
					<div className="row align-items-center">
						<div
							className="col-lg-3 col-md-6"
							style={{ marginTop: "10px" }}
						>
							<div className="footer__social">
								<p className="soc_text">Social Media</p>
								<div className="footer__social--icons">
									<Link
										to="facebook.com"
										className="facebook__icon"
									>
										<AiFillFacebook className="footer__icons" />
									</Link>
									<Link
										to="facebook.com"
										className="instagram__icon"
									>
										<AiFillInstagram className="footer__icons" />
									</Link>
									<a
										href="https://twitter.com/WhokupApp"
										className="twitter__icon"
										target="_blank"
									>
										<AiFillTwitterSquare className="footer__icons" />
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="footer__download">
								<div className="footer__download--playstore">
									<span>
										playstore
										<FaGooglePlay
											size={14}
											className="download__icons"
										/>
									</span>
								</div>
								<div className="footer__download--appstore">
									<span>
										appstore
										<DiAppstore className="download__icons" />
									</span>
								</div>
							</div>
						</div>
						<div className="col-lg-5 mr-auto ml-auto footer_agree">
							<div className="footer_share">
								<h5 onClick={toggle}>Contact Us</h5>
								{/* <AiOutlineContacts
									className="share__icon"
									size={34}
								/> */}
							</div>
							<div className="ml-3 license">
								<a
									href="https://drive.google.com/file/d/1jK2liE3x3reMaXVWt_wfBVaDSP9-KeVQ/view?usp=share_link"
									target={"_blank"}
								>
									License & Agreement
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div>
				{/* ==========Modal Component============= */}

				<Modal
					isOpen={modal}
					toggle={toggle}
					external={externalCloseBtn}
				>
					<ModalHeader>Nous-Ananke Solutions Limited</ModalHeader>
					<ModalBody>
						<p>
							We offer top notch customer services, our customer
							agents are available 24 hours and 7 days to give you
							our amiable users the best service.
						</p>
						<br />
						<h6>
							You can reach us through either of the following
							options.
						</h6>
						<div className="contact_content">
							<AiOutlineMail />
							<p>support@whokup.com</p>
						</div>
						<div className="contact_content">
							<AiFillPhone />
							<p>+234-8023800817</p>
						</div>
						<div className="contact_content">
							<FaLocationArrow />
							<p>22, Fadiya Street, Ketu, Lagos</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={toggle}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</div>
	);
};

export default Footer;
