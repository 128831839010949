import Logo from '../assets/images/white-logo.png'
import '../style/sidebar.css'
import bckg from '../assets/images/land.png'

const Sidebar = () => {
	return (
		<div className='sidebar' style={{ backgroundImage: `url(${bckg})` }}>
			<div className='img_wrapper'>
				<img src={Logo} height='120px' alt='logoBg' />
				<p>Discover. Share. Enjoy</p>
			</div>
		</div>
	)
}

export default Sidebar
