import React, { Component } from "react";
import "../style/timer.css";

class CountDown extends Component {
  constructor(props) {
    super(props);
    this.count = this.count.bind(this);
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      secounds: 0,
      time_up: ""
    };
    this.x = null;
    this.deadline = null;
  }
  count() {
    let year = new Date().getFullYear();
    let difference = +new Date(`10/30/${year}`) - +new Date();
    let dd = Math.floor(difference / (1000 * 60 * 60 * 24));
    let hh = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let mm = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    let ss = Math.floor((difference % (1000 * 60)) / 1000);

    let days = dd < 10 ? "0" + dd : dd;
    let hours = hh < 10 ? "0" + hh : hh;
    let minutes = mm < 10 ? "0" + mm : mm;
    let seconds = ss < 10 ? "0" + ss : ss;

    this.setState({ days, minutes, hours, seconds });

    if (difference < 0) {
      clearInterval(this.x);
      this.setState({
        days: 0,
        minutes: 0,
        hours: 0,
        seconds: 0,
        time_up: "TIME IS UP"
      });
    }
  }
  componentDidMount() {
    this.deadline = new Date("Oct 08, 2022 21:00:00").getTime();

    this.x = setInterval(this.count, 1000);
  }

  render() {
    const { days, seconds, hours, minutes } = this.state;

    return (
        <div className="container-fluid">
            <div className="timer__container">
                <h4>tagChief : The APP </h4>
                <div className="loading">
                    <p>
                        loading:
                    </p>
                </div>
                <div id="countdown" className="text-center">
                    <div className="row">
                        <div className="wrapper">
                            <div className="col-md-3 col-6">
                                <div className="box">
                                    <p id="day">{days}</p>
                                    <span className="text">DAYS</span>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="box">
                                    <p id="hour">{hours}</p>
                                    <span className="text">HOURS</span>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="box min">
                                    <p id="minute">{minutes}</p>
                                    <span className="text">MINUTES</span>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="box min">
                                    <p id="second">{seconds}</p>
                                    <span className="text">SECONDS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default CountDown;






// import { useState, useEffect } from "react"


// const Timer = () => {

//     const calculateTimeDifference = () => {
//         let year = new Date().getFullYear();
//         const difference = +new Date(`12/25/${year}`) - +new Date();
//         let timeLeft = {};

//         if (difference > 0) {
//             timeLeft = {
//                 days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//                 hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//                 minutes: Math.floor((difference / 1000 / 60) % 60),
//                 seconds: Math.floor((difference / 1000) % 60),

//                 day = dd < 10 ? "0" + dd : dd,
//                 hour = hh < 10 ? "0" + hh : hh,
//                 minute = mm < 10 ? "0" + mm : mm,
//                 second = ss < 10 ? "0" + ss : ss,
//             }
//         }
//         return timeLeft;
//     }

//     const [timeLeft, setTimeLeft] = useState(calculateTimeDifference());
//     const [year] = useState(new Date().getFullYear());

//     useEffect(() => {
//         setTimeout(() => {
//         setTimeLeft(calculateTimeDifference());
//         }, 1000);
//     });

//     const timerComponents = [];

//     Object.keys(timeLeft).forEach((interval) => {
//         if (!timeLeft[interval]) {
//         return;
//         }

//         timerComponents.push(
//         <span>
//             {timeLeft[interval]} {interval}{" "}
//         </span>
//         );
//     });

//     return (
//         <div>
//             <h1>HacktoberFest {year} Countdown</h1>
//             <h2>With React Hooks!</h2>
//             {timerComponents.length ? timerComponents : <span>Time's up!</span>}
//         </div>
//     )
// }

// export default Timer;