import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../assets/images/white-logo.png";
import "../style/campaign.css";
import { Link } from "react-router-dom";

const Campaign = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [email, setEmail] = useState("");
	const [mobile, setMobile] = useState("");
	const [state, setState] = useState("");
	const [lga, setLga] = useState("");
	const [occupation, setOccupation] = useState("");
	const [ward, setWard] = useState("");
	const [address, setAddress] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [nin, setNin] = useState("");
	const [maritalStatus, setMaritalStatus] = useState("");
	const [dependants, setDependants] = useState("");
	const [monthlyIncome, setMonthlyIncome] = useState("");

	const [load, setLoad] = useState(false);
	const [completed, setCompleted] = useState(false);

	const submitHandler = async () => {
		let payload = {
			lastName,
			firstName,
			middleName,
			address,
			ward,
			lga,
			state,
			mobile,
			email,
			nin,
			dateOfBirth,
			occupation,
			maritalStatus,
			dependants,
			monthlyIncome,
		};
		setLoad(true);
		await axios
			.post("https://api.whokup.com/api/campaign", payload)
			.then((res) => {
				setLoad(false);
				setCompleted(true);
				toast.success("Your form has been submitted", {
					position: "top-right",
				});
				clearInputs();
			})
			.catch((err) => {
				const error =
					err.response.data.message || "Something went wrong";
				setLoad(false);

				toast.error(error, {
					position: "top-right",
				});
			});
	};

	const clearInputs = () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setMobile("");
		setState("");
		setLga("");
		setWard("");
		setAddress("");
	};

	return (
		<div className="campaign">
			<div className="container">
				<div className="row justify-content-center">
					<div className="logo">
						<Link to="/">
							<img src={Logo} />
						</Link>
					</div>
					<div className="col-lg-9 col-md-10 side">
						<div className="box shadow-sm">
							{completed ? (
								<div className="completed">
									<p className="small">
										We've received your details, Olawale. We
										will get back to you.
									</p>
								</div>
							) : (
								<div className="board">
									<h5 className="text-center">
										Campaign Form
									</h5>

									<form
										className="form"
										onSubmit={(e) => {
											e.preventDefault();
											submitHandler();
										}}
									>
										<div className="row">
											<div className="col-md-6 mb-3">
												<label className="label">
													NIN:
												</label>
												<input
													type={"text"}
													value={nin}
													onChange={(e) =>
														setNin(e.target.value)
													}
													required={true}
													placeholder={``}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													First Name:
												</label>
												<input
													type={"text"}
													value={firstName}
													onChange={(e) =>
														setFirstName(
															e.target.value
														)
													}
													required={true}
													placeholder={``}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													Last Name:
												</label>
												<input
													type={"text"}
													value={lastName}
													onChange={(e) =>
														setLastName(
															e.target.value
														)
													}
													required={true}
													placeholder=""
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													Middle Name:
												</label>
												<input
													type={"text"}
													value={middleName}
													onChange={(e) =>
														setMiddleName(
															e.target.value
														)
													}
													required={true}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													Email:
												</label>
												<input
													type={"email"}
													value={email}
													onChange={(e) =>
														setEmail(e.target.value)
													}
													required={true}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													Mobile:
												</label>
												<input
													type={"tel"}
													value={mobile}
													onChange={(e) =>
														setMobile(
															e.target.value
														)
													}
													required={true}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													Occupation:
												</label>
												<input
													type={"text"}
													value={occupation}
													onChange={(e) =>
														setOccupation(
															e.target.value
														)
													}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													Dependants:
												</label>
												<input
													type={"number"}
													value={dependants}
													onChange={(e) =>
														setDependants(
															e.target.value
														)
													}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													Monthly Income:
												</label>
												<input
													type={"number"}
													value={monthlyIncome}
													onChange={(e) =>
														setMonthlyIncome(
															e.target.value
														)
													}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													Date Of Birth:
												</label>
												<input
													type={"date"}
													value={dateOfBirth}
													onChange={(e) =>
														setDateOfBirth(
															e.target.value
														)
													}
													required={true}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													Marital Status:
												</label>
												<input
													type={"text"}
													value={maritalStatus}
													onChange={(e) =>
														setMaritalStatus(
															e.target.value
														)
													}
													required={true}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													Ward:
												</label>
												<input
													type={"text"}
													value={ward}
													onChange={(e) =>
														setWard(e.target.value)
													}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													State:
												</label>
												<input
													type={"text"}
													value={state}
													onChange={(e) =>
														setState(e.target.value)
													}
													required={true}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="label">
													LGA:
												</label>
												<input
													type={"text"}
													value={lga}
													onChange={(e) =>
														setLga(e.target.value)
													}
													required={true}
												/>
											</div>
											<div className="col-md-12 mb-3">
												<label className="label">
													Address:
												</label>
												<textarea
													type={"text"}
													value={address}
													onChange={(e) =>
														setAddress(
															e.target.value
														)
													}
													required={true}
												/>
											</div>
											<div className="col-md-12 mb-3 text-center">
												<button
													disabled={load}
													type="submit"
												>
													{load
														? "Please Wait..."
														: "Submit"}
												</button>
											</div>
										</div>
									</form>
								</div>
							)}
						</div>
					</div>
				</div>
				<Toaster />
			</div>
		</div>
	);
};

export default Campaign;
