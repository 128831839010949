import { Helmet } from "react-helmet";
import "../style/about.css";

const Policy = () => {
	return (
		<div className="about policy">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Read through our policies to understand us better.
				</title>
			</Helmet>
			<div className="row">
				<div className="col-lg-12">
					<div className="left__content">
						<h4>Privacy Policy</h4>

						<h6>Introduction</h6>
						<p>
							We are Nous-Ananke Solutions Limited (propriety
							deployers of the “Whokup” mobile and web
							application), a Nigerian Company with offices at 22,
							Fadiya Street, Off Demurin Road, Ketu. Lagos with
							company number 1920206. For any access request,
							questions, or inquiries about how we use your
							Personal Information, please contact us at{" "}
							<a href="mailto:privacy@whokup.com">
								privacy@whokup.com
							</a>
							. Whokup does not take your trusting us with your
							information for granted. The privacy of our Users is
							important to us and we are committed to safeguarding
							it. Hence, this Privacy Policy explains your
							Personal Information which we collect, why we
							collect it, and what we do with it.
						</p>
						<h6>Overview</h6>
						<p>
							This Privacy Policy describes your privacy rights
							regarding how and when we collect, use, store, share
							and protect your information across our mobile and
							web applications, third party payment platforms,
							APIs, notifications and tools regardless of how you
							use or access them. This Privacy Policy is
							applicable to all of the mobile and web application
							platforms offered by Whokup or affiliated companies
							collectively referred to as Whokup “services”.
							Whokup is in partnership with other services and
							product providers which makes up a network of
							integrated service providers across the globe.
							“Whokup”, “we” “us” or our” in this privacy
							statement means the Whokup entity that is
							responsible for processing your personal
							information.
						</p>
						<h6>Definition</h6>
						<p>
							<b>Account</b>: means Whokup mobile or web account.
						</p>
						<p>
							<b>Whokup</b>: means Whokup mobile app deployed by
							Nous-Ananke Solutions Limited.
						</p>
						<p>
							<b>Personal Information</b>: Any information that
							can be used to identify a living person including
							email address, company name, password, financial
							information such as Bank Verification Number (BVN),
							bank account number, etc.), Government-issued
							Identity card, and/or identification it may also
							include anonymous information that is linked to you,
							for example, your internet protocol (IP), log-in
							information, address, location, device or
							transaction data.
						</p>
						<p>
							<b>Sites</b>: means any platform including but not
							limited to mobile applications, websites and social
							media platforms.
						</p>
						<p>
							<b>User</b>: means an individual who uses the
							Services or accesses the Sites and has agreed to use
							the end services of Whokup.
						</p>
						<p>
							Special Categories of Personal Information means
							details about your race or ethnicity, religious or
							philosophical beliefs, sex life, sexual orientation,
							political opinions, trade union membership,
							information about your health and genetic and
							biometric data.
						</p>
						<h6>Objective</h6>
						<p>
							This Privacy Policy statement is to provide all
							persons and organizations whose Personal Information
							we hold with description of the types of Personal
							Information we collect, the purposes for which we
							collect that Personal Information, the other parties
							with whom we may share it and the measures we take
							to protect the security of the data.
						</p>
						<p>
							Accordingly, we have developed this privacy policy
							in order for you to understand how we collect, use,
							communicate, disclose and otherwise make use of
							personal information. We have outlined our privacy
							policy below.
						</p>
						<h6>Our Privacy Principle</h6>
						<p>Whokup focuses on the following core principles</p>
						<ul>
							<li>
								To Empower the individual: Whokup wants you to
								be in charge of your personal information and to
								make your own voluntary choices about your
								Personal Information;
							</li>
							<li>
								To keep and secure personal information: Whokup
								does not take your trusting us with your
								information for granted. We take responsibility
								to ensuring that appropriate security measures
								are put in place and your personal information
								is protected;
							</li>
							<li>
								To be transparent and to educate users: For you
								to know what personal information is, how we
								collect personal information, for what purpose
								and how we secure personal information;
							</li>
							<li>
								To abide by local laws: Whokup is a global
								company with local privacy expertise. Our
								privacy practices may vary among the countries
								in which we operate to reflect local practices
								and legal requirements. Specific privacy notices
								may apply to some of our products and services.
								Please visit the webpage or digital asset of the
								specific product or service to learn more about
								our privacy and information practices in
								relation to that product or service;
							</li>
							<li>
								To collect and store personal information on the
								“need to collect” basis: Whokup collects
								personal information to perform its services for
								you. We work to have measures in place to
								prevent collecting and storing Personal
								Information beyond what we need.
							</li>
						</ul>

						<h6>Content</h6>
						<ol>
							<li>
								<strong>
									Personal Information we may collect about
									you
								</strong>
							</li>
							<li>
								<strong>
									How we get your Personal Information and why
									we have it
								</strong>
							</li>
							<li>
								<strong>
									How we may use your Personal Information
								</strong>
							</li>
							<li>
								<strong>Data security and retention</strong>
							</li>
							<li>
								<strong>
									Disclosing your Personal Information
								</strong>
							</li>
							<li>
								<strong>Marketing</strong>
							</li>
							<li>
								<strong>
									Your data protection rights and choices
								</strong>
							</li>
							<li>
								<strong>Use by Minors</strong>
							</li>
							<li>
								<strong>International transfers</strong>
							</li>
							<li>
								<strong>Data Protection Officer</strong>
							</li>
							<li>
								<strong>Training</strong>
							</li>
							<li>
								<strong>Data Protection Audit</strong>
							</li>
							<li>
								<strong>
									Dispute Resolution and Filing a Complaint
								</strong>
							</li>
							<li>
								<strong>Updates to our privacy policy</strong>
							</li>
							<li>
								<strong>Contact Us</strong>
							</li>
						</ol>

						<h6>
							1. Personal Information we may collect about you
						</h6>
						<p>
							We may collect, use, process, store, or transfer
							personal information such as:
						</p>
						<ul>
							<li>
								Identity Data: Information such as, your full
								name(s), your government-issued identity number,
								and your date of birth. This data is to enable
								us to verify your identity in order to offer our
								services to you.
							</li>
							<li>
								In order to experience the full range of
								Whokup's seamless services including the ability
								to discover your current geolocation, enable
								check-in, enable feedback and engagements,
								contact your phone contacts through Whokup,
								conduct financial transactions, we would require
								your consent granting access to your
								geo-location and your contacts. You have the
								right to withdraw your consent allowing us
								access to your contacts at any time. Please note
								that where your consent is not given or
								withdrawn, we would require the email address or
								contact of the recipient, in order to facilitate
								the fund transfer through Whokup.
							</li>
							<li>
								Contact Data: This is data that is needed to
								reach out to you, such as your contact address,
								email address, telephone number, details of the
								device you use and billing details.
							</li>
							<li>
								Contact List: This data is needed to quickly
								reach out to your friends that also use our
								services.
							</li>
							<li>
								Proile Image: This data is needed to recognize
								you.
							</li>
							<li>
								Identification documents :(such as your passport
								or any Government-issued identity card), a
								photograph (if applicable) and any other
								registration information you may provide to
								prove you are eligible to use our services and
								in compliance with regulatory requirements on
								Know Your Customer (KYC);
							</li>
							<li>
								Log/Technical information: When you access
								Whokup services, our servers automatically
								record information that your browser sends
								whenever you log into the Whokup mobile and web
								application, affiliate links you have clicked
								on, length of visit on certain pages, unique
								device identifier, log-in information, location
								and other device details.
							</li>
							<li>
								Financial Data: Information, such as Bank
								Verification Number (BVN), personal account
								number, the merchant’s name and location, the
								date and the total amount of transaction, and
								other information provided by financial
								institutions or merchants when we act on their
								and your behalf.
							</li>
							<li>
								Transactional Data: These are information
								relating to a payment or purchase when you as a
								mobile user (using one or more of our payment
								processing services) or as a corporate customer,
								are using our products or services.
							</li>
							<li>
								Marketing and Communications Data: This includes
								both a record of your decision to subscribe or
								to withdraw from receiving marketing materials
								from us or from our third parties.
							</li>
							<li>
								Records of your discussions with us, if we
								contact you and if you contact us.
							</li>
							<li>
								Records of your submissions, feedbacks, updates,
								uploads, shares, in-app transactions,
								transactions with third parties, and any and all
								one-way, two-way and/or multiple engagement
								channels, features and platforms.
							</li>
						</ul>
						<p>
							We may also collect, store, use and transfer
							non-personal information or anonymized data such as
							statistical or demographic data.
						</p>
						<p>
							We may collect any Special Categories of Personal
							Information for the purpose of surveys, analysis,
							demography analysis, and business intelligence
							mapping to help provide better service experiences
							to our users and corporate customers. If we do
							collect Special Categories of Personal Information,
							we will ensure compliance with applicable law.
						</p>
						<p>
							This Privacy Policy applies to Whokup services only.
							We do not exercise control over the sites displayed
							or linked from within our various services. These
							other sites may place their own cookies, plug-ins or
							other files on your computer, collect data or
							solicit personal information from you. Whokup does
							not control these third-party websites and we are
							not responsible for their privacy statements. Please
							consult such third parties’ privacy statements and
							set appropriate controls from your setting when
							using any third-party services.
						</p>
						<h6>
							2. How we get your Personal Information and why we
							have it
						</h6>
						<p>
							The Personal Information we have about you is
							directly made available to us when you:
						</p>
						<ul>
							<li> Sign up for a Whokup Account.</li>
							<li>Use any of our services</li>
							<li>Contact our customer support team</li>
							<li>Fill our online forms</li>
						</ul>
						<p>
							The lawful basis we rely on for processing your
							Personal Information are:
						</p>
						<ul>
							<li>
								<b>Your consent</b>: Where you agree to us
								collecting your Personal Information by using
								our Services.
							</li>
							<li>
								<b>We have a contractual obligation:</b> Without
								your Personal Information, we cannot provide our
								Services to you.
							</li>
							<li>
								<b>
									We have to a social obligation to provide
									truthful, useful, non-bot feedback and
									updates:
								</b>{" "}
								We, and the rest of the Whokup community depend
								on the feedback you provide and view so it is
								important that we know there is a real human
								being providing the feedback
							</li>
							<li>
								<b>We have a legal obligation:</b> To ensure we
								are fully compliant with all applicable laws and
								applicable financial legislations such as
								Anti-Money Laundering and Counter Terrorist
								Financing Laws, we must collect and store your
								Personal Information. We protect against fraud
								by checking your identity with your Personal
								Information.
							</li>
						</ul>
						<h6>3. How We May Use Your Personal Information</h6>
						<p>
							We may use your Personal Information we collect to:
						</p>
						<ul>
							<li>
								Create and manage any accounts you may have with
								us, verify your identity, provide our services,
								and respond to your inquiries.
							</li>
							<li>Ascertain you are a real, live human being</li>
							<li>
								Process your payment transactions (including
								authorization, clearing, chargebacks and other
								related dispute resolution activities).
							</li>
							<li>
								Protect against and prevent fraud, unauthorized
								transactions, claims and other liabilities.
							</li>
							<li>
								Provide, administer and communicate with you
								about products, services, offers, programs and
								promotions of Whokup, corporate customers,
								regulators, financial institutions, merchants
								and partners.
							</li>
							<li>
								Evaluate your interest in employment and/or
								ad-hoc engagement regarding possible part- time
								employment with Whokup.
							</li>
							<li>
								Evaluate and improve our business, including
								developing new products and services.
							</li>
							<li>
								To target advertisements, newsletter and service
								updates.
							</li>
							<li>
								As necessary to establish, exercise and defend
								legal rights.
							</li>
							<li>
								As may be required by applicable laws and
								regulations, including for compliance with Know
								Your Customers and risk assessment, Anti-Money
								Laundering, anti-corruption and sanctions
								screening requirements, or as requested by any
								judicial process, law enforcement or
								governmental agency having or claiming
								jurisdiction over Whokup or Whokup’s affiliates.
							</li>
							<li>
								To use data analytics to improve the Whokup
								mobile and web solution community, products or
								services, user experiences and to optimize
								service.
							</li>
							<li>
								For other purposes for which we provide specific
								notice at the time of collection.
							</li>
							<li>
								Store your profile image and location images you
								give us to Cloudinary, a third party image
								service.
							</li>
						</ul>
						<h6>4. Data Security and Retention</h6>
						<p>
							The security of your Personal Information is
							important to Whokup. We are committed to protecting
							the information we collect. We maintain
							administrative, technical and physical controls
							designed to protect the Personal Information you
							provide, or we collect against loss or theft, as
							well as against any unauthorized access, risk of
							loss, disclosure, copying, misuse or modification.
						</p>
						<p>
							Other security measures include but not limited to,
							secure servers, firewall, data encryption and
							granting access only to employees in order to fulfil
							their job responsibilities.
						</p>
						<p>
							Where you use a password for any of your Accounts,
							please ensure you do not share this with anyone, and
							the password is kept confidential at all times.
						</p>
						<p>
							We are committed to conducting our business in
							accordance with these principles in order to ensure
							that the confidentiality of your Personal
							Information is protected and maintained.
							Transmitting information online is not entirely
							secure. As such, we cannot guarantee that all
							information provided online is secure. We would take
							all reasonable steps to ensure that your Personal
							Information is secured and well protected.
						</p>
						<p>
							We will only retain personal information on our
							servers for as long as is reasonably necessary as
							long as we are providing Services to you. Where you
							close your Account, your information is stored on
							our servers to the extent necessary to comply with
							regulatory obligations and for the purpose of fraud
							monitoring, detection and prevention. Where we
							retain your Personal Information, we do so in
							compliance with limitation periods under the
							applicable law.
						</p>
						<h6>5. Disclosing your Personal Information</h6>
						<p>
							We may disclose or share your Personal Information
							with third parties which include our affiliates,
							employees, officers, service providers, agents,
							suppliers, subcontractors as may be reasonably
							necessary for the purposes set out in this policy.
							Whokup only shares personal information with
							External Third parties in the following
							circumstances:
						</p>
						<ul>
							<li>
								We have your consent. We require opt-in consent
								for the sharing of any personal information; We
								share Personal Information with third parties
								directly authorized by you to receive Personal
								Information, such as when you authorize a third
								party application provider to access your
								account. The use of your Personal Information by
								an authorized third party is subject to the
								third party's privacy policy and Whokup shall
								bear no liability for any breach which may arise
								from such authorization by you.
							</li>
							<li>
								We provide such information to our subsidiaries,
								affiliated companies or other trusted businesses
								or persons for the purpose of processing
								personal information on our behalf. We require
								that these parties agree to process such
								information based on our instructions and in
								compliance with this Privacy Policy and any
								other appropriate confidentiality and security
								measures.
							</li>
							<li>
								We have a good faith belief that access, use,
								preservation or disclosure of such information
								is reasonably necessary to:
								<ul>
									<li>
										satisfy any applicable law, regulation,
										legal process or enforceable
										governmental request,
									</li>
									<li>
										enforce applicable Terms of Service,
										including investigation of potential
										violations thereof,
									</li>
									<li>
										detect, prevent, or otherwise address
										fraud, security or technical issues, or
									</li>
									<li>
										protect against imminent harm to the
										rights, property or safety of Whokup,
										its users or the public as required or
										permitted by law.
									</li>
								</ul>
							</li>
							<li>
								If Whokup becomes involved in a merger,
								acquisition, or any form of sale of some or all
								of its assets, we will provide notice before
								personal information is transferred and becomes
								subject to a different privacy policy.
							</li>
						</ul>
						<h6>6. Marketing</h6>
						<p>
							We may process your Personal Information in order to
							contact you or send you marketing content and
							communication about our products, services or
							surveys. You may exercise your right to object to
							such contact from us or opt out from the marketing
							content. Please note however that if you opt- out of
							marketing content, we may still send you messages
							relating to transactions and our Services related to
							our ongoing business relationship.
						</p>
						<p>
							We may ask you for permission to send notifications
							to you. Our Services will still work if you do not
							grant us consent to send you notifications.
						</p>
						<h6>7. Your Data Protection Rights and Choices</h6>
						<p>
							Based on your location and applicable laws, below
							are the rights you have as a user in relation to
							your Personal Information:
						</p>
						<ul>
							<li>Right to be informed.</li>
							<li>
								Right to request access or copies to your
								Personal Information by signing into your
								Account or contacting us.
							</li>
							<li>
								Right to request that Whokup erase your Personal
								Information. You have the right to ask us to
								erase your Personal Information. Please note
								that this is a limited right which applies where
								the data is no longer required, or the
								processing has no legal justification. The
								exceptions to this right is where the applicable
								law or terms of our service requires Whokup to
								retain a historical archive or where we retain a
								core set of your Personal Information to ensure
								we do not inadvertently contact you in future
								where you object to your data being used for
								marketing purposes.
							</li>
							<li>
								Right to correct or rectify any Personal
								Information that you provide which may be
								incorrect, out of date or inaccurate. You also
								have the right to ask us to complete information
								you think is incomplete.
							</li>
							<li>
								Right to object to the processing of your
								Personal Information for marketing purposes. You
								have a right to ask us not to contact you for
								marketing purposes by adjusting your
								notification preference on the settings page or
								by opting out via the unsubscribe link in
								marketing emails we send you.
							</li>
							<li>
								Right to object to processing: You have the
								right to object to the processing of your
								Personal Information in certain circumstances.
								Please note that where you object to us
								processing your Personal Information, we might
								be unable to provide the services to you.
							</li>
						</ul>
						<p>
							The basis of we processing your Personal Information
							is your consent. You also have the choice at any
							time to withdraw consent which you have provided.
							You also have a choice to deactivate your Account at
							any time. You may contact us should you wish to
							de-activate your Account at any time via the Contact
							Us segment below or send us an email at{" "}
							<a href="mailto:privacy@whokup.com">
								privacy@whokup.com.
							</a>
						</p>
						<p>
							If you wish to exercise any of the rights set above,
							please contact us using the contact information
							provided in the Contact Us segment below. Where we
							are unsure of your identity, we might ask you for
							proof of your identity for security reasons before
							dealing your request.
						</p>
						<h6>8. Minor</h6>
						<p>
							Whokup’s mobile app, websites and applications are
							not directed at persons under the age of eighteen
							(18) and we do not collect any Personal Information
							knowingly or directly from minors who fall within
							this category.
						</p>
						<p>
							Where you have any belief that Whokup has mistakenly
							or unknowingly collected information from a minor,
							please contact us using the information provided
							under the Contact Us section to enable us
							investigate and restrict such data.
						</p>
						<h6>9. International Data Transfers</h6>
						<p>
							Where Personal Information is to be transferred to a
							country outside Nigeria, Whokup shall put adequate
							measures in place to ensure the security of such
							Personal Information and to ensure same is done
							securely and in accordance with the Nigerian Data
							Protection Regulation.
						</p>
						<h6>10. Data Protection Officer</h6>
						<p>
							Whokup has appointed a Data Protection Officer(s)
							(DPO) responsible for overseeing the Company's data
							protection strategy and its implementation to ensure
							compliance with the NDPR requirements. The DPO is
							knowledgeable on data privacy and protection
							principles and is familiar with the provisions of
							the NDPR.
						</p>
						<h6>11. Training</h6>
						<p>
							Whokup ensures that employees who collect, access
							and process Personal Information receive adequate
							data privacy and protection training in order to
							develop the necessary knowledge, skills and
							competence required to effectively manage the
							compliance framework under this Policy and the
							Nigerian Data Protection Regulation (NDPR) with
							regard to the protection of Personal Information. On
							an annual basis, Whokup develops a capacity building
							plan for its employees on data privacy and
							protection in line with the NDPR.
						</p>
						<h6>12. Data Protection Audit</h6>
						<p>
							Whokup shall conduct an annual data protection audit
							through a licensed Data Protection Compliance
							Organization (DPCOs) to verify Whokup’s compliance
							with the provisions of the NDPR and other applicable
							data protection laws.
						</p>
						<p>
							The audit report will be certified and filed by the
							DPCO to the National Information Technology
							Development Agency (NITDA) as required under the
							NDPR.
						</p>
						<h6>13. Dispute Resolutions and Filing a Compliant</h6>
						<p>
							If you have any complaints regarding this Privacy
							Policy, please contact us at privacy@Whokup.com. We
							will investigate and work on resolutions for
							complaints and disputes regarding use and disclosure
							of personal information within thirty (30) days in
							accordance with this Privacy Policy and in
							accordance with the Nigerian Data Protection
							Regulation.
						</p>
						<h6>14. Updates to our privacy policy</h6>
						<p>
							From time to time, we may change, amend or review
							this Privacy Policy from time to time to reflect new
							services or changes in our Privacy Policy and place
							any updates on this page. All changes made will be
							posted on this page and where changes will
							materially affect you, we will notify you of this
							change by placing a notice online or via mail. If
							you keep using our Services, you consent to all
							amendments of this Privacy Policy.
						</p>
						<h6>15. Contact us</h6>
						<p>
							All access requests, questions, comments, complaints
							and other requests regarding the privacy policy
							should be sent to{" "}
							<a href="mailto:privacy@whokup.com">
								privacy@whokup.com.
							</a>
							. We may request additional details from you
							regarding your complaints and keep records of your
							requests and resolution.
						</p>
						<p>
							<strong>Effective Date: February 2, 2023</strong>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Policy;
