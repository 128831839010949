import { Helmet } from "react-helmet";
import Carousel from "../components/Carousel";
import "../style/home.css";

const Home = () => {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Whokup | Discover. Share. Enjoy</title>
			</Helmet>
			<div className="mb-5">
				<Carousel />
			</div>
		</>
	);
};

export default Home;
