import { useState } from "react"
import { NavLink, Link } from 'react-router-dom'
import OutsideClick from "./OutsideClick"
import bckg from '../assets/images/land.png'

import Logo from '../assets/images/white-logo.png'

import '../style/navbar.css'

const Navbar = () => {

    const [ open, setOpen ] = useState(false);

    const closeNavbar = () => {
		setOpen(false);
	};

    return (
        <nav>
            <div className="container">
                <div className="navbar">
                    <div></div>
                    <div className="navbar__link">
                        <NavLink to="/" exact>
                            <h5>home</h5>
                        </NavLink>
                        <NavLink to="/about">
                            <h5>about</h5>
                        </NavLink>
                        <NavLink to="/features">
                            <h5>features</h5>
                        </NavLink>
                        <NavLink to="/faq">
                            <h5>faq</h5>
                        </NavLink>
                        <NavLink to="/policy">
                            <h5>policies</h5>
                        </NavLink>
                    </div>
                </div>
                
            </div>
            <div className="mobile_nav" style={{ backgroundImage: `url(${bckg})` }}>
                <div className="mobile_logo">
                    <Link to="/">
                        <img 
                            src={Logo} 
                            alt="logo" 
                            width="50px"
                            height="50px"
                        />
                    </Link> 
                </div>    
                <div className="hamburger" onClick={() => setOpen(!open)}>
                    <div className="line" />
                    <div className="line" />
                    <div className="line" />
                </div>
            </div>
            <OutsideClick handleToggle={closeNavbar}>
                <ul className="menu" 
                    style={{
                        right : open ? "0px" : "-50vw",
                        transition: "0.3s ease-in-out"
                    }} 
                    onClick={() => setOpen(!open)}
                >   
                    <li className="cancel__btn">X</li>
                    <li className="menu__item">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="menu__item">
                        <Link to="/about">About</Link>
                    </li>
                    <li className="menu__item">
                        <Link to="/features">Features</Link>
                    </li>
                    <li className="menu__item">
                        <Link to="/faq">FAQ</Link>
                    </li>
                    <li className="menu__item">
                        <Link to="/policy">Policies</Link>
                    </li>
                </ul>
            </OutsideClick>
        </nav>
    )
}

export default Navbar;