import { Helmet } from 'react-helmet'
import "../style/faq.css"

const Faq = () => {
    return (
        <section>

            <Helmet>
				<meta charSet="utf-8" />
				<title>Frequently Asked Questions</title>
			</Helmet>
            <div className="row">
                <div className='col-md-12'>
                    <div className='recent'>
                        <div className='row'>
                            <h4>Frequently Asked Questions</h4>
                            <div className='col-md-12'>
                                <h5><span></span> Where can I get the App? </h5>
                                <p>
                                   You can download our App or Playstore or Appstore, by searching for tagChief. 
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <h5><span></span> I tried registering but did not get an OTP</h5>
                                <p>
                                    You can just click on the resend button to get another OTP.
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <h5><span></span> How do I withdraw my acquired coins</h5>
                                <p>
                                    We have a featured called tagPay, you coins are exchanged to money through tis platform.
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <h5><span></span> Can I tag an already tagged location?</h5>
                                <p>
                                    No you can't, as you can only tag a location just onced and it gets saved.
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <h5><span></span> How do I contact support?</h5>
                                <p>
                                    You can send a meesage to us through the App, we are online 24/7.
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <h5><span></span> Where can I get the App? </h5>
                                <p>
                                   You can download our App or Playstore or Appstore, by searching for tagChief. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq;